import { Multicall } from "ethereum-multicall";

import BEP20ABI from "../../ABI/BEP20.json";
import BcashABI from "../../ABI/Bcash.json";
import config from "../../config/config";

import { isAddress, getFormatMulticall } from "../../helper/custom";
import { connection } from "../../helper/connection";
import { convert } from "../../helper/convert";

import store from '../../store';

export function getReferalAddr() {
    /* eslint-disable-next-line */
    try {
        var name = "ref";
        var url = window.location.href
        var name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&;]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        if (results && results[2]) {
            var refAddr = decodeURIComponent(results[2].replace(/\+/g, ' '));
            return refAddr;
        }
    } catch (err) {
        return "";
    }

}

export async function checkUser() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var userinfo = await Contract.methods.userinfo(
            address
        ).call();
        return {
            isExist: userinfo.isExist,
        }
    } catch (err) {
        return {
            isExist: false
        }
    }
}

export async function validation(refaddress) {
    var error = "";
    var joinAmt = 0;
    var isAllowance = true;
    var get = await connection();
    var web3 = get.web3;
    var address = get.address;
    try {
        if (get.connect === "no") {
            error = "Please connect smart chain network";
        } else if (refaddress === "") {
            error = "Please enter address";
        } else if (!isAddress(refaddress)) {
            error = "Please enter valid address";
        } else {

            const multicall = new Multicall({
                web3Instance: web3,
            });

            var Contract = [
                {
                    reference: "balanceOf",
                    contractAddress: config.busdAddress,
                    abi: BEP20ABI,
                    calls: [
                        {
                            reference: "balanceOf",
                            methodName: "balanceOf",
                            methodParameters: [address],
                        },
                        {
                            reference: "allowance",
                            methodName: "allowance",
                            methodParameters: [address, config.contract],
                        },
                    ]
                },
                {
                    reference: "userinfo",
                    contractAddress: config.contract,
                    abi: BcashABI,
                    calls: [
                        {
                            reference: "userinfo",
                            methodName: "userinfo",
                            methodParameters: [refaddress],
                        },
                        {
                            reference: "userinfo",
                            methodName: "userinfo",
                            methodParameters: [address],
                        },
                        {
                            reference: "JoinAmount",
                            methodName: "JoinAmount",
                            methodParameters: [],
                        }
                    ]
                }
            ];

            const results = await multicall.call(Contract);
            var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
            balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
            var allowance = await getFormatMulticall(results, "balanceOf", 1);
            allowance = parseInt(allowance.hex) / 10 ** 18;
            let referinfo = await getFormatMulticall(results, "userinfo", 0);
            let userinfo = await getFormatMulticall(results, "userinfo", 1);
            var JoinAmount = await getFormatMulticall(results, "userinfo", 2);
            JoinAmount = parseInt(JoinAmount.hex) / 10 ** 18;
            joinAmt = JoinAmount;

            if (userinfo) {
                error = "Already joined";
            } else if (address.toLowerCase() === refaddress.toLowerCase()) {
                error = "wallet address & referral address should not be same";
            } else if (!referinfo) {
                error = "Invalid referral address";
            } else if (JoinAmount > balanceOf) {
                error = `Please make sure you have ${joinAmt} BUSD in your wallet`;
            }

            if (allowance >= JoinAmount) {
                isAllowance = false;
            }
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        error = "Oops something went wrong.!"
    }

    return {
        errorvalidate: error,
        isAllowance: isAllowance,
        joinAmt
    };

}

export async function approveToken() {
    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);

        if (balance === 0) {
            return {
                isAllowed: false,
                approvalAmt: 0,
                error: `Please make sure you have gas fee in your wallet.`
            }
        }

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BEP20ABI, config.busdAddress);
        var approveAmt = 100000 * (10 ** 18);
        approveAmt = await convert(approveAmt);

        var estimateGas = await Contract.methods.approve(
            config.contract,
            approveAmt.toString()
        ).estimateGas({ from: address });

        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isAllowed: false,
                approvalAmt: 0,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.approve(
            config.contract,
            approveAmt.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
            && result.events.Approval.returnValues && result.events.Approval.returnValues
            && result.events.Approval.returnValues.value) ?
            parseFloat(result.events.Approval.returnValues.value) : 0
        approvalAmt = approvalAmt / 10 ** 18;

        return {
            approvalAmt: approvalAmt,
            isAllowed: (result && result.status) ? result.status : false,
            error: ""
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return {
            approvalAmt: 0,
            isAllowed: false,
            error: ""
        }
    }

}

export async function JoinPlan(ref) {
    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);

        if (balance === 0) {
            return {
                result: null,
                errMsg: `Please make sure you have gas fee in your wallet.`
            }
        }

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var Amt = 25 * (10 ** 18);
        Amt = await convert(Amt);

        var estimateGas = await Contract.methods.regUser(
            ref,
            Amt.toString()
        ).estimateGas({ from: address });
        estimateGas = estimateGas + 10000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                result: null,
                errMsg: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.regUser(
            ref,
            Amt.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            result: result,
            errMsg: ""
        }
    } catch (err) {
        console.log(err, 'errerrerr11')
        var errMsg = err.toString();
        var pos = errMsg.search("insufficient");
        if (pos >= 0) {
            errMsg = "Please make sure you have gas fee in your wallet"
        } else {
            errMsg = "Failed to join"
        }
        return {
            result: "",
            errMsg: errMsg
        }
    }

}

