import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.js";
import AOS from "aos";
import "aos/dist/aos.css";

import { useSelector } from "react-redux";
import Navbar_mob from "../../components/Navbar_mob.js";
import CopyToClipboard from "react-copy-to-clipboard";
import ConfirmWithdraw from "../../components/confirmWithdraw";

import {
  getUserInfo,
  getInitialuserInfo
} from "../dashboard/dashboardinfo.js"

import {
  getLeaderBoardAutoPool
} from "../dashboard/autopoolinfo";

import { getLeaderShipInfo } from "../dashboard/autopoolinfo.js"

import { formatNumber } from "../../helper/custom";


export default function Mobileview(props) {

  const walletConnection = useSelector((state) => state.walletConnection);

  const [isLoad, setisLoad] = useState(false);
  const [dashInfo, setdashInfo] = useState(getInitialuserInfo());
  const [copytext, setcopytext] = React.useState(false);

  const [totalEarn, settotalEarn] = React.useState(0);
  const [leaderinfo, setleaderinfo] = React.useState({ leadership: "", leadershiplogo: "" });


  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    loadInitial();
  }, [walletConnection]);

  function loadInitial() {
    getDetails();
  }

  async function getDetails() {
    setisLoad(true);
    if (walletConnection && walletConnection.address !== "") {
      var resp = await getUserInfo();
      setdashInfo(resp);
      leaderPool(resp.totalearnedAmount);
      var { leadership, leadershiplogo } = await getLeaderShipInfo();
      console.log(leadership, leadershiplogo, 'leadership, leadershiplogo')
      setleaderinfo({ leadership: leadership, leadershiplogo: leadershiplogo });
    }
    setisLoad(false);

  }

  async function leaderPool(amt) {
    setTimeout(async function () {
      var resp = await getLeaderBoardAutoPool();
      let totalLeadAuto = (resp && resp.total) ? resp.total : 0;
      var total = parseFloat(amt) + parseFloat(totalLeadAuto);
      total = formatNumber(total, 5);
      settotalEarn(total);
    }, 1000);

  }



  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  async function depositSuccess() {
    getDetails();
  }


  return (
    <div>
      {/* <Aboveinfo /> */}
      <Navbar_mob />
      <div className="dash_ban dash_ban_mob">
        <div className="container">
          <div className="center">
            <h2>ECO FINANCE BOARD</h2>
            {leaderinfo && leaderinfo.leadership && leaderinfo.leadership !== "" &&
              <div className="round" >
                <p>Leadership</p>
                <img src={require(`../../assets/images/${leaderinfo.leadershiplogo}`)} className="img-fluid" alt="img" />
                <h5>{leaderinfo.leadership}</h5>
              </div>
            }
          </div>
        </div>
      </div>


      <div className="dashboard dashboard_mob">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              {isLoad && <div className="text-center dashboadmobile">
                <img src={require("../../assets/images/Loader.gif")} className="img-fluid" alt="img" />
              </div>
              }
              <div className="box">
                <div>
                  <p>BSC Code</p>
                  <h5 className="h5tag" >B{dashInfo.userId}</h5>
                </div>
                <img src={require("../../assets/images/user.png")} className="img-fluid" alt="img" />
              </div>
              <div className="box aff_box">
                <p>Affiliate Link</p>
                <div class="input-group mb-3">
                  <input type="text" readOnly className="form-control" value={dashInfo.referalshow} />
                  <span class="input-group-text">
                    <CopyToClipboard text={dashInfo.referalLink} onCopy={() => onCopy()}>
                      {!copytext ?
                        <img src={require("../../assets/images/copy_green.png")} className="img-fluid" alt="img" />
                        :
                        <img src={require("../../assets/images/tick_green.png")} className="img-fluid" alt="img" />
                      }

                    </CopyToClipboard>
                  </span>
                </div>
              </div>
              <div className="box">
                <div>
                  <p>TOTAL EARNED BUSD</p>
                  <h5 className="h5tag" >{totalEarn}</h5>
                </div>
                <img src={require("../../assets/images/busd.png")} className="img-fluid" alt="img" />
              </div>
              <div className="box">
                <div className="w-100" >
                  <p>Withdraw Wallet</p>
                  <div className="fl_x">
                    <h5 className="h5tag">${dashInfo.earnedAmount}</h5>
                    <button
                      disabled={(dashInfo && dashInfo.earnedAmount &&
                        parseFloat(dashInfo.earnedAmount) > 0) ? false : true}
                      className="linear my-3"
                      data-bs-toggle="modal"
                      data-bs-target="#confirmwithdraw_modal"
                    >WITHDRAW</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end" >
            <p className="next" >
              <Link to="/dashboard">
                <span>Next</span>
                <img src={require("../../assets/images/arw.png")} className="img-fluid ms-2" alt="img" />
              </Link>
            </p>
          </div>
        </div>
      </div>


      <ConfirmWithdraw
        ondepositSuccess={depositSuccess}
      />

      <Footer />
    </div>
  );
}
