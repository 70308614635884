import React, { useState, useEffect } from "react";
import { toastAlert } from "../helper/toastAlert";
import {
    getAllowanceBalance,
    claimEarn
} from "../pages/dashboard/dashboardinfo";

import { setBalances } from "../reducers/Actions.js";
import { formatNumber } from "../helper/custom";
import config from "../config/config";

import { useDispatch } from "react-redux";

export default function ConfirmWithdraw(props) {

    const dispatch = useDispatch();

    const [isLoad, seisLoad] = useState(false);
    const [txid, settxid] = useState("");

    var { ondepositSuccess } = props;

    useEffect(() => {
    }, [txid]);

    async function submitWithdraw() {

        seisLoad(true)
        try {
            var { iswithdraw, error, hash } = await claimEarn();
            if (iswithdraw) {
                settxid(hash);
                window.$("#confirmwithdraw_modal").modal('hide');
                setTimeout(function () {
                    window.$("#withdraw_modal").modal('show');
                }, 300);
                setTimeout(async function () {
                    ondepositSuccess();
                    var { balanceOf, allowance, bnbBal } = await getAllowanceBalance();
                    balanceOf = formatNumber(balanceOf);
                    bnbBal = formatNumber(bnbBal);
                    dispatch(
                        setBalances({
                            tokenbalance: balanceOf,
                            tokenallowance: allowance,
                            balance: bnbBal,
                            isLoad: "yes"
                        })
                    );
                }, 500)

            } else {
                toastAlert("error", error);
            }

            seisLoad(false)
        } catch (err) {
            seisLoad(false)
            toastAlert("error", "Please try again later");
            console.log(err, 'errerrerrerr')
        }
    }

    return (
        <>
            <div
                className="modal fade primary_modal deposit_modal"
                id="confirmwithdraw_modal"
                tabIndex={-1}
                aria-labelledby="confirmwithdrawModal"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header px-0">
                            <h5 className="modal-title" id="confirmwithdrawModal">
                                Confirm Withdraw
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body px-0">
                            <div>
                                <div className="mb-3 deposit_body">
                                    <span className="mb-3"> Please make sure you have gas fee in your wallet.</span>
                                    <button disabled={isLoad} type="button" className="primary_btn mt-4" onClick={() => submitWithdraw()}>
                                        Confirm
                                        {isLoad && <i className="fas fa-spinner fa-spin"></i>}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal"
                id="withdraw_modal"
                tabIndex={-1}
                aria-labelledby="withdrawModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="withdrawModalLabel">
                                Congratulations, Successfully Withdraw
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {/* {txid && txid !== "" && */}
                                <div className="m-2 mb-3 text-center">
                                    {/* <a target="_blank" href={config.txLink + "/tx/" + txid}>View Bscscan</a> */}
                                    <img height="200" width="200" src={require("../assets/images/Bsmart_02.gif")} className="img-fluid" alt="img" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
