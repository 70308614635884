import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/images/logo.svg';

import Web3 from "web3";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  type WalletClient,
  useWalletClient,
  useNetwork,
  useDisconnect,
} from "wagmi";

import { isMobile } from 'react-device-detect';

import { setWallet, setBalances } from "../reducers/Actions";
import config from "../config/config";
import ConnectWallet from "./ConnectWallet";
import { walletClientToSigner } from "../helper/getprovider";
import { formatNumber } from "../helper/custom";
import { getAllowanceBalance } from "../helper/common";


import BcashABI from "../ABI/Bcash.json";

export default function Navbar_mob(props) {

  const dispatch = useDispatch();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const location = useLocation();
  const navigate = useNavigate();

  const walletConnection = useSelector((state) => state.walletConnection);
  const userBalances = useSelector((state) => state.balances);

  const { data: walletClient } = useWalletClient({ chainId: config.networkId });
  const [useraddress, setuseraddress] = useState("");
  const [isUser, setisUser] = useState(false);

  useEffect(() => {
    setConnection();
    // eslint-disable-next-line
  }, [walletClient, chain]);

  async function setConnection() {
    setisUser(false);
    if (walletClient && chain && parseInt(chain.id) === config.networkId) {
      var { transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      var userAddr =
        walletClient && walletClient.account && walletClient.account.address
          ? walletClient.account.address
          : "";
      var chainVal = chain && chain.id ? chain.id : config.networkId;
      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: transport,
          connect: "yes",
        })
      );
      setuseraddress(userAddr);

      var Contract = new web3.eth.Contract(BcashABI, config.contract);
      var userinfo = await Contract.methods.userinfo(userAddr).call();
      if (userinfo && userinfo.isExist) {
        setisUser(true);
      } else {
        if (location.pathname === "/dashboard") {
          navigate("/join-now");
        }
      }

      setTimeout(async function () {
        try {
          var { balanceOf, allowance, bnbBal } = await getAllowanceBalance();
          balanceOf = formatNumber(balanceOf);
          bnbBal = formatNumber(bnbBal);
          dispatch(
            setBalances({
              tokenbalance: balanceOf,
              tokenallowance: allowance,
              balance: bnbBal,
              isLoad: "yes",
            })
          );
        } catch (err) { }
      }, 500);

    } else {
      var chainVal =
        localStorage.getItem("network") &&
          parseInt(localStorage.getItem("network")) > 0
          ? parseInt(localStorage.getItem("network"))
          : config.networkId;
      var web3 = new Web3(config.rpcURl);

      if ((chain && parseInt(chain.id) !== config.networkId) || !chain) {
        if (location.pathname === "/dashboard") {
          navigate("/join-now");
        }
      }
      dispatch(
        setWallet({
          network: chainVal,
          web3: web3,
          address: "",
          provider: "",
          connect: "no",
        })
      );
    }
  }

  function logout() {
    disconnect();
    localStorage.clear();
    navigate("/");
  }


  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      80 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      80 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      0 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar main_navbar_mob navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {walletConnection &&
                  walletConnection.address !== "" &&
                  walletConnection.connect === "yes" ? (
                  <>
                    <div className="dropdown me-3">
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {useraddress}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>BUSD Balance:</label>
                            <span className="text-break d-block">{userBalances && userBalances.balance
                              ? userBalances.balance
                              : 0}{" "} </span>
                          </a>
                        </li>
                        {isUser && location.pathname !== "/dashboard" && (
                          <li>
                            {isMobile ?
                              <Link to="/dashboard-nav" className="dropdown-item">
                                Dashboard
                              </Link>
                              :
                              <Link to="/dashboard" className="dropdown-item">
                                Dashboard
                              </Link>
                            }
                          </li>
                        )}
                        {!isUser && location.pathname !== "/join-now" && (
                          <li>
                            <Link to="/join-now" className="dropdown-item">
                              Join now
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                    <button
                      className="primary_btn"
                      onClick={() => logout()}
                    >
                      <img src={require("../assets/images/logout.png")} className="img-fluid me-2" alt="img" />
                      <span> LOGOUT</span>
                    </button>
                  </>) :
                  <button
                    className="primary_btn ms-3"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <img src={require("../assets/images/wallet_icon.png")} className="img-fluid me-2" alt="img" />
                    <span> Connect Wallet</span>
                  </button>
                }
                {/* <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <ConnectWallet />
    </>
  );
}
