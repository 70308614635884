import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars";
import Aboveinfo from "../../components/aboveinfo.js";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import Countdown from "react-countdown";
import moment from "moment";
import { get_notification } from "../../Action/controller";
import DepositModal from "../../components/depositModal";
import ConfirmWithdraw from "../../components/confirmWithdraw";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import {
  getUserInfo,
  getInitialuserInfo,
  getLevelPrice,
  getClassNameLevel,
  getWithdrawGasFee,
} from "../dashboard/dashboardinfo.js";

import {
  getTreeImage,
  firstTree,
  secondTree,
  thirdTree,
} from "../dashboard/treeinfo.js";

import {
  getAutopoolAddress,
  getDistributionDate,
  getLeaderBoardAutoPool,
} from "../dashboard/autopoolinfo";

import { formatNumber } from "../../helper/custom";

var zeroaddr = "0x0000000000000000000000000000000000000000";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  const walletConnection = useSelector((state) => state.walletConnection);

  const [isLoad, setisLoad] = useState(false);
  const [dashInfo, setdashInfo] = useState(getInitialuserInfo());
  const [copytext, setcopytext] = React.useState(false);

  const [firstRow, setfirstRow] = React.useState([]);
  const [secondRow, setsecondRow] = React.useState([]);
  const [thirdRow, setthirdRow] = React.useState([]);
  const [notification, setnotification] = useState("");
  const [istree, setistree] = React.useState(false);
  const [levelPrice, setlevelPrice] = useState({
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
  });

  const [autoLeader, setautoLeader] = useState({
    l1: -1,
    l2: -1,
    l3: -1,
    l4: -1,
  });

  const [leaderAuto, setleaderAuto] = React.useState({
    lPrimary: 0,
    lGrand: 0,
    lRoyal: 0,
    lMajestic: 0,
    aPrimary: 0,
    aGrand: 0,
    aRoyal: 0,
    aMajestic: 0,
    total: 0,
  });
  const [txId, settxId] = React.useState("");
  const [withdrawGas, setwithdrawGas] = React.useState(0.003);

  const [totalEarn, settotalEarn] = React.useState(0);
  const [isDownload, setisDownload] = React.useState(false);

  const [userdetail1, setuserdetail1] = useState([]);
  const [userdetail2, setuserdetail2] = useState([]);
  const [userdetail3, setuserdetail3] = useState([]);
  const [rootInfo, setrootInfo] = useState({ address: "", id: 0, level: 0 });

  const [timer, settimer] = React.useState(0);
  const [startissues, setstartissues] = React.useState(0);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    loadInitial();
  }, [walletConnection]);

  useEffect(() => {
    getNextAutoTime();
  }, [timer]);

  function getNextAutoTime() {
    settimer(0);
    var currDate = new Date();
    setstartissues(currDate);
    let minutes = currDate.getMinutes();
    var remainter = minutes % 30;
    remainter = 30 - remainter;
    remainter = remainter * 60;
    remainter = remainter * 1000;
    settimer(remainter);
  }

  function loadInitial() {
    const d = new Date();
    let minutes = d.getMinutes();
    console.log(minutes, "minutesminutes");
    getnotification();
    getDetails();
    loadTree("");
  }

  async function getDetails() {
    setisLoad(true);
    if (walletConnection && walletConnection.address !== "") {
      var resp = await getUserInfo();
      setdashInfo(resp);
      leaderPool(resp.totalearnedAmount);
      setrootInfo({ id: resp.userId, currentLevel: resp.currentLevel });
    }
    setisLoad(false);

    var { levelPrice1, levelPrice2, levelPrice3, levelPrice4 } =
      await getLevelPrice();
    setlevelPrice({
      level1: levelPrice1,
      level2: levelPrice2,
      level3: levelPrice3,
      level4: levelPrice4,
    });

    setTimeout(async function () {
      var { estimateGas } = await getWithdrawGasFee();
      setwithdrawGas(estimateGas);
    }, 1000);
  }

  async function leaderPool(amt) {
    setTimeout(async function () {
      var { pool1, pool2, pool3, pool4 } = await getAutopoolAddress();
      setautoLeader({ l1: pool1, l2: pool2, l3: pool3, l4: pool4 });
      var resp = await getLeaderBoardAutoPool();
      setleaderAuto(resp);
      let totalLeadAuto = resp && resp.total ? resp.total : 0;
      var total = parseFloat(amt) + parseFloat(totalLeadAuto);
      total = formatNumber(total, 5);
      settotalEarn(total);
    }, 1000);
  }

  async function getnotification() {
    let result = await get_notification();
    if (
      result &&
      result.data &&
      result.data.data &&
      result.data.data.startdate
    ) {
      let date1 = moment();
      let date2 = moment(result.data.data.startdate);
      let date3 = moment(result.data.data.enddate);
      let difference = date1.diff(date2, "seconds");
      let difference1 = date1.diff(date3, "seconds");
      if (difference > 0) {
        if (difference1 <= 0) {
          setnotification(result.data.data.notification);
        }
      }
    }
  }

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  async function loadTree(addr) {
    if (addr && addr !== "") {
      var index = userdetail1.findIndex(
        (val) => val.address && val.address.toLowerCase() === addr.toLowerCase()
      );
      if (index !== -1) {
        setrootInfo({
          id: userdetail1[index].id,
          currentLevel: userdetail1[index].currentLevel,
        });
      }
    }

    setistree(true);
    var isfirstloop = false;
    let { tree1, isfirst, user1 } = await firstTree(addr);
    isfirstloop = isfirst;
    setuserdetail1(user1);
    setfirstRow(tree1);

    var tree2 = [];
    var issecondloop = false;
    if (tree1 && tree1.length > 0) {
      var { tree2, issecond, user2 } = await secondTree(tree1, isfirstloop);
      issecondloop = issecond;
      setuserdetail2(user2);
      setsecondRow(tree2);
    }

    if (tree2 && tree2.length > 0) {
      var { tree3, isthird, user3 } = await thirdTree(tree2, issecondloop);
      if (tree3 && tree3.length > 0) {
        setuserdetail3(user3);
        setthirdRow(tree3, isthird);
      }
    }

    setistree(false);
  }

  async function depositSuccess() {
    getDetails();
  }

  async function printDocument() {
    setisDownload(true);
    try {
      const input = document.getElementById("treeplan");
      var w = document.getElementById("treeplan").offsetWidth;
      var h = document.getElementById("treeplan").offsetHeight;
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("L", "px", [w, h]);
        pdf.addImage(imgData, "JPEG", 0, 0, w, h);
        pdf.save("Treedownload.pdf");
        setisDownload(false);
      });
    } catch (err) {
      setisDownload(false);
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return (
      <div className="upgrade_timer">
        <p>Auto Upgrade timer</p>
        <div className="upgrade_timer_wrap">
          <span className="time_txt">{minutes}</span>
          <span className="time_dot">:</span>
          <span className="time_txt">{seconds}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="dash_ban">
        <div className="container">
          <h2>ECO FINANCE BOARD</h2>
          <div className="flx">
            <span className="me-2">Affiliate Link </span>
            <div className="input-group">
              <input
                type="text"
                readOnly
                className="form-control"
                value={dashInfo.referalshow}
              />
              <span className="input-group-text">
                <CopyToClipboard
                  text={dashInfo.referalLink}
                  onCopy={() => onCopy()}
                >
                  {!copytext ? (
                    <img
                      src={require("../../assets/images/copy.png")}
                      className="img-fluid"
                      alt="img"
                    />
                  ) : (
                    <img
                      src={require("../../assets/images/tick2.png")}
                      className="img-fluid"
                      alt="img"
                    />
                  )}
                </CopyToClipboard>
              </span>
            </div>
          </div>
          {copytext && <p className="copied">Copied!</p>}
          {timer > 0 && (
            <div className="text-center">
              {/* <button className="primary_btn refereshbtn" onClick={() => loadInitial()}>Refresh</button> */}
              <Countdown
                date={Date.now() + parseInt(timer)}
                renderer={renderer}
                onComplete={() => getNextAutoTime()}
                autoStart={true}
                key={startissues}
              />
            </div>
          )}

          {/* pop ups  */}
          {/* <button className="primary_btn refereshbtn"  data-bs-toggle="modal" data-bs-target="#deposit_modal" >deposit_modal</button>
          <button className="primary_btn refereshbtn"  data-bs-toggle="modal" data-bs-target="#withdraw_modal">withdraw_modal</button>
          <button className="primary_btn refereshbtn"  data-bs-toggle="modal" data-bs-target="#congrats_modal">congrats_modal</button> */}
        </div>
      </div>

      <section className="plan_box plan_box_dash mb-3">
        <div className="container">
          <div className="maxbox">
            <div className="row">
              <div className="col-lg-4">
                <div className="box one">
                  <img
                    src={require("../../assets/images/user.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <div className="text-start">
                    <h3>B{dashInfo.userId}</h3>
                    <p> BSC Code</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-md-12 col-lg-4">
                <div className="box two">
                  <img
                    src={require("../../assets/images/participants.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <div>
                    <h3>{dashInfo.totalUsers}</h3>
                    <p>All Participants</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box three">
                  <img
                    src={require("../../assets/images/busd.png")}
                    className="img-fluid"
                    alt="img"
                  />
                  <div>
                    <h3>{totalEarn}</h3>
                    <p>Total Earned BUSD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoad && (
        <div className="text-center dashboardloader">
          <ReactLoading type={"bars"} color="#033a83" className="loading" />
        </div>
      )}
      <div className="container">
        <div className="news mb-3">
          <marquee>
            {notification &&
              notification != "" &&
              notification != undefined &&
              notification != null && <p>{notification}</p>}
          </marquee>
        </div>
      </div>

      <div className="dashboard">
        <div className="container">
          <div className="grid">
            <div className="grid_flx">
              <div className="box">
                <div>
                  <p>Direct Sponsors</p>
                  <h5 className="h5tag">{dashInfo.directReferCount}</h5>
                </div>
                <img
                  src={require("../../assets/images/user_three.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="box">
                <div>
                  <p>Direct Sponsor Income</p>
                  <h5 className="h5tag">${dashInfo.directSponcerIncome}</h5>
                </div>
                <img
                  src={require("../../assets/images/b2.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="box">
                <div>
                  <p>Auto Sponsor Income</p>
                  <h5 className="h5tag">${dashInfo.autoSponcerIncome}</h5>
                </div>
                <img
                  src={require("../../assets/images/b3.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="box">
                <div>
                  <p>Team Development Bonus</p>
                  <h5 className="h5tag">${dashInfo.teamDevelopementBonus}</h5>
                </div>
                <img
                  src={require("../../assets/images/b3.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="box">
                <div>
                  <p>Immediate Downline Bonus</p>
                  <h5 className="h5tag">${dashInfo.downlineBonus}</h5>
                </div>
                <img
                  src={require("../../assets/images/b3.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
              <div className="box">
                <div>
                  <p>Team Upgradation Bonus </p>
                  <h5 className="h5tag">${dashInfo.upgradationBonus}</h5>
                </div>
                <img
                  src={require("../../assets/images/b3.png")}
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="grid_flx">
              <div className="row mrgin">
                <div className="col-xl-9 d-flex">
                  <div className="box_bdr w-100">
                    <div className="row">
                      <div className="col-md-4 d-flex">
                        <div className="box">
                          <div className="w-100">
                            <p>Upgrade Wallet</p>
                            <div className="">
                              <h5 className="h5tag">
                                ${dashInfo.upgradeAmount}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex px-1">
                        <div className="box">
                          <div className="w-100">
                            <p>Withdraw Wallet</p>
                            <div className="">
                              <h5 className="h5tag">
                                ${dashInfo.earnedAmount}
                              </h5>

                              <button
                                disabled={
                                  dashInfo &&
                                    dashInfo.earnedAmount &&
                                    parseFloat(dashInfo.earnedAmount) > 0
                                    ? false
                                    : true
                                }
                                className="linear my-3"
                                data-bs-toggle="modal"
                                data-bs-target="#confirmwithdraw_modal"
                              >
                                WITHDRAW
                              </button>
                              {/* <button className="button mt-2">WITHDRAW</button> */}
                              {/* <p> Withdraw gas fee {withdrawGas} BNB</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Next Distribution Date</p>
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../../assets/images/calender.png")}
                                className="img-fluid"
                                alt="img"
                              />
                              <div>
                                <span className="ms-2">
                                  {getDistributionDate().resp}
                                </span>
                                {/* <span className="ms-2 d-block">
                                  {getDistributionDate().resp1}
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>Level Upgradation</h6>
                    <div className="busd_boxes">
                      <div
                        className={
                          getClassNameLevel(dashInfo.currentLevel).className1
                        }
                      >
                        <p>25 BUSD</p>
                      </div>
                      <div>
                        <div
                          className={
                            getClassNameLevel(dashInfo.currentLevel).className2
                          }
                        >
                          <p>40 BUSD</p>
                        </div>
                        {dashInfo.currentLevel === 1 && (
                          <button
                            className="primary_btn linear"
                            data-bs-toggle="modal"
                            data-bs-target="#deposit_modal"
                          >
                            Deposit
                          </button>
                        )}
                      </div>
                      <div>
                        <div
                          className={
                            getClassNameLevel(dashInfo.currentLevel).className3
                          }
                        >
                          <p>125 BUSD</p>
                        </div>
                        {dashInfo.currentLevel < 3 && (
                          <button
                            className={
                              dashInfo.currentLevel === 2
                                ? "primary_btn linear "
                                : "primary_btn disable linear"
                            }
                            disabled={
                              dashInfo.currentLevel === 2 ? false : true
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#deposit_modal"
                          >
                            Deposit
                          </button>
                        )}
                      </div>
                      <div>
                        <div
                          className={
                            getClassNameLevel(dashInfo.currentLevel).className4
                          }
                        >
                          <p>450 BUSD</p>
                        </div>
                        {dashInfo.currentLevel < 4 && (
                          <button
                            className={
                              dashInfo.currentLevel === 3
                                ? "primary_btn linear"
                                : "primary_btn disable linear"
                            }
                            disabled={
                              dashInfo.currentLevel === 3 ? false : true
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#deposit_modal"
                          >
                            Deposit
                          </button>
                        )}
                      </div>
                    </div>

                    <h6 className="text-center mt-4">
                      My Team{" "}
                      {istree && <i className="fas fa-spinner fa-spin"></i>}
                    </h6>

                    <div className="lvl_flx">
                      <div>
                        <button
                          disabled={istree || isDownload ? true : false}
                          type="button"
                          className="primary_btn py-1"
                          onClick={() => printDocument()}
                        >
                          Download
                          {isDownload && (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                        </button>
                      </div>

                      <div>
                        <button
                          disabled={
                            firstRow && firstRow[0] && firstRow[0] !== zeroaddr
                              ? false
                              : true
                          }
                          className="primary_btn py-1"
                          onClick={() => loadTree(firstRow[0])}
                        >
                          Team A
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={
                            firstRow && firstRow[1] && firstRow[1] !== zeroaddr
                              ? false
                              : true
                          }
                          className="primary_btn py-1"
                          onClick={() => loadTree(firstRow[1])}
                        >
                          Team B
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={
                            firstRow && firstRow[2] && firstRow[2] !== zeroaddr
                              ? false
                              : true
                          }
                          className="primary_btn py-1"
                          onClick={() => loadTree(firstRow[2])}
                        >
                          Team C
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={
                            firstRow && firstRow[3] && firstRow[3] !== zeroaddr
                              ? false
                              : true
                          }
                          className="primary_btn py-1"
                          onClick={() => loadTree(firstRow[3])}
                        >
                          Team D
                        </button>
                      </div>
                    </div>
                    <Scrollbars style={{ width: "100%", height: 450 }}>
                      <div className="plan_pool" id="treeplan">
                        <div className="plan_pool_head">
                          <img
                            src={require("../../assets/images/user_1.png")}
                            id="user_one"
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                        <ReactTooltip
                          anchorId="user_one"
                          place="bottom"
                          content={
                            <p className="tooltip_data text-center">
                              B{rootInfo.id} <br />
                              <span>level {rootInfo.currentLevel}</span>
                            </p>
                          }
                        />
                        {/* First Row */}
                        <div className="plan_pool_first_row">
                          {firstRow &&
                            firstRow.length > 0 &&
                            firstRow.map((item1, index1) => {
                              var logo = getTreeImage(index1, item1);
                              var index = userdetail1.findIndex(
                                (val) =>
                                  val.address &&
                                  val.address.toLowerCase() ===
                                  item1.toLowerCase()
                              );
                              var userid = 0;
                              var level = 0;
                              if (index !== -1) {
                                userid = userdetail1[index].id;
                                level = userdetail1[index].currentLevel;
                              }
                              return (
                                <div
                                  id={item1}
                                  className="plan_pool_head_child"
                                  data-tip
                                  data-for={item1}
                                >
                                  {logo && logo !== "" && (
                                    <img
                                      src={require(`../../assets/images/${logo}`)}
                                      id={"user_two" + item1}
                                      className="img-fluid"
                                      alt="Icon"
                                    />
                                  )}
                                  <ReactTooltip
                                    anchorId={"user_two" + item1}
                                    place="bottom"
                                    content={
                                      <p className="tooltip_data text-center">
                                        B{userid} <br />
                                        <span>level {level}</span>
                                      </p>
                                    }
                                  />
                                </div>
                              );
                            })}
                        </div>

                        {/* Second Row */}
                        <div className="plan_pool_second_row">
                          {secondRow &&
                            secondRow.length > 0 &&
                            secondRow.map((item2, index2) => {
                              return (
                                <div>
                                  {item2 &&
                                    item2.length > 0 &&
                                    item2.map((subitem1, subindex1) => {
                                      var logo = getTreeImage(index2, subitem1);
                                      var index = userdetail2.findIndex(
                                        (val) =>
                                          val.address &&
                                          val.address.toLowerCase() ===
                                          subitem1.toLowerCase()
                                      );
                                      var userid = 0;
                                      var level = 0;
                                      if (index !== -1) {
                                        userid = userdetail2[index].id;
                                        level = userdetail2[index].currentLevel;
                                      }
                                      return (
                                        <div
                                          id={subitem1}
                                          className="plan_pool_head_child"
                                          data-tip
                                          data-for={subitem1}
                                        >
                                          <img
                                            src={require(`../../assets/images/${logo}`)}
                                            id={
                                              "user_three" + index2 + subindex1
                                            }
                                            className="img-fluid"
                                            alt="Icon"
                                          />
                                          <ReactTooltip
                                            anchorId={
                                              "user_three" + index2 + subindex1
                                            }
                                            place="bottom"
                                            content={
                                              <p className="tooltip_data text-center">
                                                B{userid} <br />
                                                <span>level {level}</span>
                                              </p>
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>

                        {/* third Row */}
                        <div className="plan_pool_third_row">
                          {thirdRow &&
                            thirdRow.length > 0 &&
                            thirdRow.map((item3, index3) => {
                              return (
                                <div className="plan_pool_second_row">
                                  {item3 &&
                                    item3.list &&
                                    item3.list.length > 0 &&
                                    item3.list.map((item4, index4) => {
                                      return (
                                        <div>
                                          {item4 &&
                                            item4.length > 0 &&
                                            item4.map((item5, index5) => {
                                              var logo1 = getTreeImage(
                                                index3,
                                                item5
                                              );
                                              var index = userdetail3.findIndex(
                                                (val) =>
                                                  val.address &&
                                                  val.address.toLowerCase() ===
                                                  item5.toLowerCase()
                                              );
                                              var userid = 0;
                                              var level = 0;
                                              if (index !== -1) {
                                                userid = userdetail3[index].id;
                                                level =
                                                  userdetail3[index]
                                                    .currentLevel;
                                              }
                                              return (
                                                <div
                                                  id={item5}
                                                  className="plan_pool_head_child"
                                                  data-tip
                                                  data-for="id_tooltip3"
                                                >
                                                  <img
                                                    src={require(`../../assets/images/${logo1}`)}
                                                    id={
                                                      "user_four" +
                                                      index3 +
                                                      index4 +
                                                      index5
                                                    }
                                                    className="img-fluid"
                                                    alt="Icon"
                                                  />
                                                  <ReactTooltip
                                                    anchorId={
                                                      "user_four" +
                                                      index3 +
                                                      index4 +
                                                      index5
                                                    }
                                                    place="top"
                                                    content={
                                                      <p className="tooltip_data text-center">
                                                        B{userid} <br />
                                                        <span>
                                                          level {level}
                                                        </span>
                                                      </p>
                                                    }
                                                  />
                                                </div>
                                              );
                                            })}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
                <div className="col-xl-3 d-flex">
                  <div className="w-100">
                    <div className="box board_chg board">
                      <div className="flx mb-3">
                        <p className="heading mb-0">Leadership Board</p>
                        <img
                          src={require("../../assets/images/leadership.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l1) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <div>
                          <img
                            src={require("../../assets/images/primary.png")}
                            className="img-fluid"
                            alt="img"
                          />
                          <p>Primary</p>
                        </div>
                        <span>${leaderAuto.lPrimary}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l2) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <div>
                          <img
                            src={require("../../assets/images/grand.png")}
                            className="img-fluid"
                            alt="img"
                          />
                          <p>Grand</p>
                        </div>
                        <span>${leaderAuto.lGrand}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l3) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <div>
                          <img
                            src={require("../../assets/images/royal.png")}
                            className="img-fluid"
                            alt="img"
                          />
                          <p>Royal</p>
                        </div>
                        <span>${leaderAuto.lRoyal}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l4) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <div>
                          <img
                            src={require("../../assets/images/majestic.png")}
                            className="img-fluid"
                            alt="img"
                          />
                          <p>Majestic</p>
                        </div>
                        <span>${leaderAuto.lMajestic}</span>
                      </div>
                    </div>
                    <div className="box board board_chg">
                      <div className="flx mb-3">
                        <p className="heading mb-0">Auto Pool Board</p>
                        <img
                          src={require("../../assets/images/auto_pool.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l1) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <p>Auto Pool 1 </p>
                        <span>${leaderAuto.aPrimary}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l2) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <p>Auto Pool 2 </p>
                        <span>${leaderAuto.aGrand}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l3) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <p>Auto Pool 3 </p>
                        <span>${leaderAuto.aRoyal}</span>
                      </div>
                      <div
                        className={
                          autoLeader && parseFloat(autoLeader.l4) >= 0
                            ? "selbox active"
                            : "selbox finished_lvl"
                        }
                      >
                        <p>Auto Pool 4 </p>
                        <span>${leaderAuto.aMajestic}</span>
                      </div>
                    </div>
                    <div className="box board board_chg mb-0 p-3 total">
                      <div className="flx mb-1 justify-content-start">
                        {/* <p className="heading mb-0 text-dark">Total</p> */}
                        <div className="selbox active mb-0 flex-wrap">
                          <p className="me-1">Total : </p>
                          <span className="word-wrap">${leaderAuto.total}</span>
                        </div>
                        <img
                          src={require("../../assets/images/b4.png")}
                          className="img-fluid ms-2"
                          alt="img"
                        />
                        <div id="app-title">
                          <img
                            src={require("../../assets/images/information.png")}
                            className="img-fluid ms-2 info_img"
                            alt="img"
                          />
                        </div>
                        <ReactTooltip
                          anchorId="app-title"
                          place="bottom"
                          content="X = Registration + Upgraded BUSD "
                        />
                      </div>
                      <div className="tot_flx">
                        <img
                          src={require("../../assets/images/leadership.png")}
                          className="img-fluid"
                          alt="img"
                        />
                        <span>+</span>
                        <img
                          src={require("../../assets/images/auto_pool.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* congrats modal  */}
      <div
        className="modal fade primary_modal congrats_modal"
        id="congrats_modal"
        tabIndex={-1}
        aria-labelledby="congratsModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="congratsModalLabel">
                {/* Upgrade Wallet */}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="congrats">
                  <h3>Congratulations!</h3>
                  <h5>you have earned 2x </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DepositModal
        upgradeAmt={dashInfo.upgradeAmount}
        currentLevel={dashInfo.currentLevel}
        levelPrice={levelPrice}
        ondepositSuccess={depositSuccess}
      />
      <ConfirmWithdraw ondepositSuccess={depositSuccess} />
      <Footer />
    </div>
  );
}
