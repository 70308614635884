import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import Home from './pages/home.js';
import Dashboard from './pages/dashboard/dashboard';
import Mobileview from "./pages/dashboard/mobileview.js";

import { WagmiConfig } from 'wagmi';

import { createConfig, configureChains } from 'wagmi'
import { infuraProvider } from "wagmi/providers/infura";
import { mainnet, bsc, polygon, avalanche, fantom, arbitrum, optimism, bscTestnet } from "wagmi/chains";

import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { SnackbarProvider } from 'notistack';

import config from './config/config';
import Joinnow from "./pages/joinNow/join-now";


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [infuraProvider({
    apiKey: "cb89ebb21cdccb2e1b591e189e27706a",
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        version: 2
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/dashboard-nav", element: <Mobileview /> },
    { path: "/join-now", element: <Joinnow /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <SnackbarProvider />
      <Provider store={store}>
        <WagmiConfig config={wconfigInitial}>
          <App />
        </WagmiConfig>
      </Provider>
    </Router>
  );
};

export default AppWrapper;