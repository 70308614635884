import React from "react";

import config from "../config/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            © 2023 All Rights Reserved
            <p>Smart-contract:</p>
            <a target="_blank" href={`${config.txLink}/address/${config.contract}`}><p className="addr" >{config.contract} </p></a>
            {/* <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
