var key = {}
if (process.env.NODE_ENV === "production") {
  // key = {
  //   projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
  //   networkId: 56,
  //   contract: "0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4",
  //   busdAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  //   frontUrl: "https://bsmartcash.io",
  //   serverUrl: "https://controlsapi.bsmartcash.io/",
  //   txLink: "https://bscscan.com",
  //   rpcUrl: "https://bsc.publicnode.com",
  //   bscanApi: 'https://api.bscscan.com/api?module=account&action=txlist&address=0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4&page=1&offset=10&sort=desc&apikey=C7Q16AU6BMYD1DTPHHKH2QQ4XFDIKAGGV5'
  // };
  key = {
    projectId: "cb89ebb21cdccb2e1b591e189e27706a",//wallet connect
    networkId: 97,
    contract: "0xa6e1786909D736481526eF61D2e2eefBF4201a25",
    busdAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    frontUrl: "https://bcash-mlm-frontend.pages.dev",
    serverUrl: "https://bcashregapi.wearedev.team/",
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://data-seed-prebsc-2-s1.binance.org:8545",
    adminAddress: "0x373F886904048975034488320E37d171E092C135",
    bscanApi: 'https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xa6e1786909D736481526eF61D2e2eefBF4201a25&page=1&offset=20&sort=desc&apikey=I6WH378GVYIUI5INBH2RUP9BSXEYU7N88Q',
    baseUrl: "https://bcashapi.wearedev.team/",
  };
} else {
  key = {
    projectId: "cb89ebb21cdccb2e1b591e189e27706a",//wallet connect
    networkId: 97,
    contract: "0xed205b49432714dd40969fe1036debc6a93da908",
    busdAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    frontUrl: "https://bcash-mlm-frontend.pages.dev",
    serverUrl: "https://bcashregapi.wearedev.team/",
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://data-seed-prebsc-2-s1.binance.org:8545",
    adminAddress: "0x373F886904048975034488320E37d171E092C135",
    bscanApi: 'https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xed205b49432714dd40969fe1036debc6a93da908&page=1&offset=20&sort=desc&apikey=I6WH378GVYIUI5INBH2RUP9BSXEYU7N88Q',
    baseUrl: "http://localhost:3097/",
  };
}
export default key;