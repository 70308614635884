import { Multicall } from "ethereum-multicall";
import axios from "axios";
import moment from "moment";

import { readWeb3, connection } from "../helper/connection";
import { getFormatMulticall1, getFormatMulticall, formatNumber } from "../helper/custom";

import config from "../config/config";

import BcashABI from "../ABI/Bcash.json";
import BEP20ABI from "../ABI/BEP20.json";

const abiDecoder = require('abi-decoder');
abiDecoder.addABI(BcashABI);

export async function aboveDetail() {
    try {
        var web3 = readWeb3();
        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "infos",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "totalUsers",
                        methodName: "totalUsers",
                        methodParameters: [],
                    },
                    {
                        reference: "getUsersJoinedLast24Hours",
                        methodName: "getUsersJoinedLast24Hours",
                        methodParameters: [],
                    },

                ]
            },
            {
                reference: "contractBalance",
                contractAddress: config.busdAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [config.contract],
                    }

                ]
            }
        ];
        const results = await multicall.call(Contract);
        var totalUsers = await getFormatMulticall1(results, "infos", 0);
        var contractBalance = await getFormatMulticall1(results, "contractBalance", 0);

        var contractBalance = (contractBalance && contractBalance[0] && contractBalance[0].hex) ? parseInt(contractBalance[0].hex) : 0;
        contractBalance = contractBalance / 10 ** 18;
        contractBalance = formatNumber(contractBalance);

        totalUsers = (totalUsers && totalUsers[0] && totalUsers[0].hex) ? parseInt(totalUsers[0].hex) : 0;
        var todayjoin = await getFormatMulticall1(results, "infos", 1);
        todayjoin = (todayjoin && todayjoin[0] && todayjoin[0].hex) ? parseInt(todayjoin[0].hex) : 0

        return {
            totalUsers,
            todayjoin,
            balance: contractBalance
        }
    } catch (err) {
        return {
            totalUsers: 0,
            todayjoin: 0,
            balance: 0
        }
    }
}

export async function getLatestTransaction() {
    try {
        var web3 = readWeb3();
        let respData = await axios({
            'method': 'get',
            'url': `${config.bscanApi}`,
        });

        const BscContract = new web3.eth.Contract(BcashABI, config.contract);

        var resp = (respData && respData.data && respData.data.result) ? respData.data.result : [];
        var allList = [];
        for (var i = 0; i < resp.length; i++) {

            var trans_hash = await web3.eth.getTransaction(resp[i].hash);
            if (trans_hash && trans_hash.input) {
                let trans_detail = abiDecoder.decodeMethod(trans_hash.input);
                var from = resp[i].from;
                var timeStamp = resp[i].timeStamp;
                let userdet = await BscContract.methods.userinfo(from).call();

                let date = moment.unix(timeStamp).toDate();
                var now = moment(new Date()); //todays date
                var end = moment(date); // another date
                var duration = now.diff(end, "minutes");
                var duration1 = now.diff(end, "hours");

                const timeago = moment(date).fromNow();

                var methodName = (trans_detail && trans_detail.name && trans_detail.name) ? trans_detail.name : "";
                var amount = 0;
                if (methodName == "regUser") {
                    var getEvtVal = await BscContract.getPastEvents("registerEvent", { fromBlock: parseFloat(resp[i].blockNumber), toBlock: parseFloat(resp[i].blockNumber) });

                    amount = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues && getEvtVal[0].returnValues._amount) ? getEvtVal[0].returnValues._amount : 0;
                    amount = parseFloat(amount) / 10 ** 18;
                    amount = formatNumber(amount);
                } else if (methodName == "claimEarnings") {
                    var getEvtVal = await BscContract.getPastEvents("claimEvent", { fromBlock: parseFloat(resp[i].blockNumber), toBlock: parseFloat(resp[i].blockNumber) });
                    amount = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues && getEvtVal[0].returnValues._amount) ? getEvtVal[0].returnValues._amount : 0;
                    amount = parseFloat(amount) / 10 ** 18;
                    amount = formatNumber(amount);
                }
                // else if (methodName == "depositUpgrade") {
                //     var getEvtVal = await BscContract.getPastEvents("depositUpgrade", { fromBlock: parseFloat(resp[i].blockNumber), toBlock: parseFloat(resp[i].blockNumber) });
                //     amount = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues && getEvtVal[0].returnValues._amount) ? getEvtVal[0].returnValues._amount : 0;
                //     amount = parseFloat(amount) / 10 ** 18;
                //     amount = formatNumber(amount);
                // }


                if (methodName == "regUser" || methodName == "claimEarnings" || methodName == "buyLevelEvent") {

                    let datas = {
                        "address": from,
                        "userid": userdet.id,
                        "event": trans_detail.name,
                        "value": 0,
                        "hash": resp[i].hash,
                        "min": duration,
                        "hours": duration1,
                        "amount": amount,
                        "timeago": timeago

                    }
                    allList.push(datas);
                }
            }
        }

        return allList;
    } catch (err) {
        console.log(err, 'getLatestTransaction', config.rpcUrl)
        return [];
    }
}

export async function getAllowanceBalance() {

    try {
        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var address = get.address;
        var Contract = [
            {
                reference: "balanceOf",
                contractAddress: config.busdAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.contract],
                    },
                ]
            }];

        const results = await multicall.call(Contract);
        var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
        balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
        var allowance = await getFormatMulticall(results, "balanceOf", 1);
        allowance = parseInt(allowance.hex) / 10 ** 18;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;


        return {
            balanceOf,
            allowance,
            bnbBal: getBalance
        }
    } catch (err) {
        return {
            balanceOf: 0,
            allowance: 0,
            bnbBal: getBalance
        }
    }

}