import { Multicall } from "ethereum-multicall";

import BcashABI from "../../ABI/Bcash.json";
import config from "../../config/config";

import { isAddress, getFormatMulticall1 } from "../../helper/custom";
import { connection } from "../../helper/connection";

var zeroaddr = "0x0000000000000000000000000000000000000000";
var listEmp = [zeroaddr, zeroaddr, zeroaddr, zeroaddr];
var treeLimit = 4;

export async function firstTree(addr) {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        if (addr && addr !== "") {
            address = addr;
        }
        console.log(address, 'addressaddressmmmmm')
        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "viewUserReferral",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [address],
                    }

                ]
            }
        ];
        const results = await multicall.call(Contract);
        var referals = await getFormatMulticall1(results, "viewUserReferral", 0);

        var ref1List = [];
        var userList1 = [];
        var isfirst = false;
        for (var r = 0; r < treeLimit; r++) {
            var address = (referals && referals[r] && referals[r] !== "") ? referals[r] : zeroaddr;
            if (address !== zeroaddr) {
                isfirst = true;//avoid loop next tree
                userList1.push(address);
            }
            ref1List.push(address);
        }

        var { userdetail } = await getuserInfos(userList1);

        return {
            tree1: ref1List,
            isfirst: isfirst,
            user1: userdetail
        };
    } catch (err) {
        return {
            tree1: ref1List,
            isfirst: false,
            user1: []
        };
    }

}

export async function secondTree(list, isLoop) {
    var secondList = [listEmp, listEmp, listEmp, listEmp];

    try {
        if (isLoop) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {
                var addr = zeroaddr;
                if (isAddress(list[r])) {
                    addr = list[r];
                }

                Contract.push({
                    reference: "viewUserReferral-" + r,
                    contractAddress: config.contract,
                    abi: BcashABI,
                    calls: [
                        {
                            reference: "viewUserReferral",
                            methodName: "viewUserReferral",
                            methodParameters: [addr],
                        }

                    ]
                })

            }
            const results = await multicall.call(Contract);
            secondList = [];
            var refList = [];
            var userList2 = [];
            var issecond = false;
            for (var r1 = 0; r1 < list.length; r1++) {
                var addr1 = list[r1];
                if (addr1 !== zeroaddr) {
                    issecond = true;
                }
                var referals = await getFormatMulticall1(results, "viewUserReferral-" + r1, 0);
                for (var r2 = 0; r2 < treeLimit; r2++) {
                    var address = (referals && referals[r2] && referals[r2] !== "" && addr1 !== zeroaddr) ? referals[r2] : zeroaddr;
                    refList.push(address);
                    userList2.push(address);
                }

                secondList.push(refList);
                refList = [];
            }
            var { userdetail } = await getuserInfos(userList2);

            return {
                tree2: secondList,
                issecond: issecond,
                user2: userdetail
            };
        } else {
            return {
                tree2: secondList,
                issecond: false,
                user2: []
            };
        }
    } catch (err) {
        return {
            tree2: secondList,
            issecond: false,
            user2: []
        };
    }

}

export async function thirdTree(list, isLoop) {

    var thirdList = [{ list: [listEmp, listEmp, listEmp, listEmp] }, { list: [listEmp, listEmp, listEmp, listEmp] }, { list: [listEmp, listEmp, listEmp, listEmp] }, { list: [listEmp, listEmp, listEmp, listEmp] }];

    try {
        if (isLoop) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {

                for (var r1 = 0; r1 < list[r].length; r1++) {

                    var index = r + "-" + r1;

                    var addr = zeroaddr;
                    if (list[r] && list[r][r1] && isAddress(list[r][r1])) {
                        addr = list[r][r1];
                    }

                    Contract.push({
                        reference: "viewUserReferral-" + index,
                        contractAddress: config.contract,
                        abi: BcashABI,
                        calls: [
                            {
                                reference: "viewUserReferral",
                                methodName: "viewUserReferral",
                                methodParameters: [addr],
                            }

                        ]
                    });
                }

            }
            const results = await multicall.call(Contract);
            thirdList = [];
            var refList = [];
            var isthird = false;
            var userList3 = [];
            for (var r2 = 0; r2 < list.length; r2++) {
                thirdList.push({ list: [] });
                for (var r3 = 0; r3 < list.length; r3++) {
                    var addr1 = (list[r2] && list[r2][r3]) ? list[r2][r3] : zeroaddr;
                    if (addr1 !== zeroaddr) {
                        isthird = true;
                    }
                    var index1 = r2 + "-" + r3;
                    var referals = await getFormatMulticall1(results, "viewUserReferral-" + index1, 0);
                    for (var r4 = 0; r4 < treeLimit; r4++) {
                        var address = (referals && referals[r4] && referals[r4] !== "" && addr1 !== zeroaddr) ? referals[r4] : zeroaddr;
                        refList.push(address);
                        userList3.push(address);
                    }
                    thirdList[r2].list.push(refList);
                    refList = [];
                }

            }
            var { userdetail } = await getuserInfos(userList3);
            return {
                tree3: thirdList,
                isthird: isthird,
                user3: userdetail
            };
        } else {
            return {
                tree3: thirdList,
                isthird: false,
                userList3: [],
                user3: []
            };
        }
    } catch (err) {
        return {
            tree3: thirdList,
            isthird: false,
            userList3: [],
            user3: []
        };
    }


}


export async function getuserInfos(list) {

    try {
        if (list && list.length > 0) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {

                var addr = zeroaddr;
                if (isAddress(list[r])) {
                    addr = list[r];
                }

                Contract.push({
                    reference: "users-" + r,
                    contractAddress: config.contract,
                    abi: BcashABI,
                    calls: [
                        {
                            reference: "users",
                            methodName: "users",
                            methodParameters: [addr],
                        }

                    ]
                })

            }
            const results = await multicall.call(Contract);
            var userList = [];
            for (var r1 = 0; r1 < list.length; r1++) {

                var users = await getFormatMulticall1(results, "users-" + r1, 0);

                userList.push({
                    id: (users && users[1] && users[1].hex) ? parseInt(users[1].hex) : 0,
                    currentLevel: (users && users[3] && users[3].hex) ? parseInt(users[3].hex) : 0,
                    address: list[r1]
                })
            }

            return {
                userdetail: userList
            };
        } else {
            return {
                userdetail: []
            };
        }

    } catch (err) {
        return {
            userdetail: []
        };
    }

}

export function getTreeImage(index, addr) {
    var logo = "user_3_violet.png";
    if (index === 0 && addr && addr !== "") {
        logo = (addr !== zeroaddr) ? "user_3_violet.png" : "user_3_violet_empty.png";
    } else if (index === 1 && addr && addr !== "") {
        logo = (addr !== zeroaddr) ? "user_3_blue.png" : "user_3_blue_empty.png";
    } else if (index === 2 && addr && addr !== "") {
        logo = (addr !== zeroaddr) ? "user_3_green.png" : "user_3_green_empty.png";
    } else if (index === 3 && addr && addr !== "") {
        logo = (addr !== zeroaddr) ? "user_3_yellow.png" : "user_3_yellow_empty.png";
    }

    return logo;

}