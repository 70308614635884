import axios from 'axios';
import config from '../config/config';

export const get_notification = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.serverUrl}adminapi/getnotification`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}