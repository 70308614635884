import { Multicall } from "ethereum-multicall";

import BEP20ABI from "../../ABI/BEP20.json";
import BcashABI from "../../ABI/Bcash.json";
import config from "../../config/config";

import { getFormatMulticall1, getFormatMulticall, shortText, formatNumber } from "../../helper/custom";
import { connection, readWeb3 } from "../../helper/connection";
import { convert } from "../../helper/convert";

import store from '../../store';

export function getInitialuserInfo() {

    return {
        referalshow: "",
        referalLink: "",
        directSponcerIncome: 0,
        directReferCount: 0,
        autoSponcerIncome: 0,
        teamDevelopementBonus: 0,
        downlineBonus: 0,
        upgradationBonus: 0,
        totalUsers: 0,
        userId: 0,
        earnedAmount: 0,
        totalearned: 0,
        upgradeAmount: 0,
        currentLevel: 0,
        isFetched: false,
        totalearnedAmount: 0,
        remaining: 0
    }
}

export async function getUserInfo() {


    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userinfo",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "userinfo",
                        methodName: "userinfo",
                        methodParameters: [address],
                    },
                    {
                        reference: "directReferCount",
                        methodName: "directReferCount",
                        methodParameters: [address],
                    },
                    {
                        reference: "totalUsers",
                        methodName: "totalUsers",
                        methodParameters: [],
                    },
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "upgradeRemaining",
                        methodName: "upgradeRemaining",
                        methodParameters: [address],
                    },

                ]
            }
        ];

        const results = await multicall.call(Contract);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var directReferCount = await getFormatMulticall1(results, "userinfo", 1);
        var totalUsers = await getFormatMulticall1(results, "userinfo", 2);
        var users = await getFormatMulticall1(results, "userinfo", 3);
        var upgradeRemaining = await getFormatMulticall1(results, "userinfo", 4);

        var text = shortText(address);

        var userId = (userinfo && userinfo[1] && userinfo[1].hex) ? parseInt(userinfo[1].hex) : 0;

        var directSponcerIncome = (userinfo && userinfo[2] && userinfo[2].hex) ? parseInt(userinfo[2].hex) : 0;
        directSponcerIncome = directSponcerIncome / 10 ** 18;
        directSponcerIncome = formatNumber(directSponcerIncome);

        var autoSponcerIncome = (userinfo && userinfo[3] && userinfo[3].hex) ? parseInt(userinfo[3].hex) : 0;
        autoSponcerIncome = autoSponcerIncome / 10 ** 18;
        autoSponcerIncome = formatNumber(autoSponcerIncome);

        var teamDevelopementBonus = (userinfo && userinfo[4] && userinfo[4].hex) ? parseInt(userinfo[4].hex) : 0;
        teamDevelopementBonus = teamDevelopementBonus / 10 ** 18;
        teamDevelopementBonus = formatNumber(teamDevelopementBonus);

        var downlineBonus = (userinfo && userinfo[5] && userinfo[5].hex) ? parseInt(userinfo[5].hex) : 0;
        downlineBonus = downlineBonus / 10 ** 18;
        downlineBonus = formatNumber(downlineBonus);

        var upgradationBonus = (userinfo && userinfo[6] && userinfo[6].hex) ? parseInt(userinfo[6].hex) : 0;
        upgradationBonus = upgradationBonus / 10 ** 18;
        upgradationBonus = formatNumber(upgradationBonus);

        directReferCount = (directReferCount && directReferCount[0] && directReferCount[0].hex)
            ? parseInt(directReferCount[0].hex) : 0;
        totalUsers = (totalUsers && totalUsers[0] && totalUsers[0].hex)
            ? parseInt(totalUsers[0].hex) : 0

        var earnedAmount = (users && users[4] && users[4].hex) ? parseInt(users[4].hex) : 0;
        earnedAmount = earnedAmount / 10 ** 18;
        earnedAmount = formatNumber(earnedAmount);

        var upgradeAmount = (users && users[6] && users[6].hex) ? parseInt(users[6].hex) : 0;
        upgradeAmount = upgradeAmount / 10 ** 18;
        upgradeAmount = formatNumber(upgradeAmount);

        var currentLevel = (users && users[3] && users[3].hex) ? parseInt(users[3].hex) : 0;

        var totalearnedAmount = (users && users[5] && users[5].hex) ? parseInt(users[5].hex) : 0;
        totalearnedAmount = totalearnedAmount / 10 ** 18;
        totalearnedAmount = formatNumber(totalearnedAmount);

        totalearnedAmount = parseFloat(directSponcerIncome) +
            parseFloat(autoSponcerIncome) +
            parseFloat(teamDevelopementBonus) +
            parseFloat(downlineBonus) + parseFloat(upgradationBonus)

        upgradeRemaining = (upgradeRemaining && upgradeRemaining[0] && upgradeRemaining[0].hex) ? parseInt(upgradeRemaining[0].hex) : 0;
        //console.log(upgradeRemaining, 'upgradeRemainingupgradeRemainingupgradeRemaining')
        upgradeRemaining = upgradeRemaining / 10 ** 18;
        upgradeRemaining = formatNumber(upgradeRemaining);
        //console.log(upgradeRemaining, 'upgradeRemainingupgradeRemainingupgradeRemaining')

        if (parseInt(currentLevel) >= 4) {
            earnedAmount += upgradeAmount;
            upgradeAmount = 0;
        }

        return {
            referalshow: config.frontUrl + "/join-now?ref=" + text,
            referalLink: config.frontUrl + "/join-now?ref=" + address,
            directSponcerIncome,
            directReferCount,
            autoSponcerIncome,
            teamDevelopementBonus,
            downlineBonus,
            upgradationBonus,
            totalUsers,
            userId,
            earnedAmount,
            upgradeAmount,
            currentLevel,
            isFetched: true,
            totalearnedAmount,
            remaining: upgradeRemaining
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return getInitialuserInfo()
    }

}


export async function depositUpgrade(amt) {
    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);
        console.log(balance, 'balancebalancebalance')
        if (balance === 0) {
            return {
                isdeposit: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        amt = parseFloat(amt);
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        console.log(amt, 'upgradeAmtupgradeAmt')
        var upgradeAmt = parseFloat(amt) + parseFloat(100000);
        upgradeAmt = await convert(upgradeAmt);
        console.log(upgradeAmt, 'upgradeAmtupgradeAmt111')
        var estimateGas = await Contract.methods.depositUpgrade().estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        // if (parseFloat(estimateGas) / 10 ** 8 > balance) {
        //     return {
        //         isdeposit: false,
        //         hash: "",
        //         error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
        //     }
        // }

        var result = await Contract.methods.depositUpgrade().send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        return {
            isdeposit: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: "Failed to upgrade your account"
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to upgrade your account"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isdeposit: false,
            hash: "",
            error: errorMsg
        }
    }
}

export async function claimEarn() {
    try {

        var currentProvider = store.getState();
        var balances = currentProvider.balances;
        var balance = parseFloat(balances.balance);

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BcashABI, config.contract);

        if (balance === 0) {
            return {
                iswithdraw: false,
                hash: "",
                error: `Please make sure you have gas fee in your wallet.`
            }
        }

        var estimateGas = await Contract.methods.claimEarnings().estimateGas({ from: address });

        estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                iswithdraw: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.claimEarnings().send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        return {
            iswithdraw: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: "Failed to withdraw"
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var errorMsg = "Failed to withdraw"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        }
        return {
            iswithdraw: false,
            hash: "",
            error: errorMsg
        }
    }
}

export async function getWithdrawGasFee() {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BcashABI, config.contract);
        var estimateGas = await Contract.methods.claimEarnings().estimateGas({ from: address, gasPrice: gasPrice });
        estimateGas = estimateGas + 100000;
        estimateGas = estimateGas / 10 ** 8;
        estimateGas = formatNumber(estimateGas);
        return {
            estimateGas: estimateGas
        }
    } catch (err) {
        return {
            estimateGas: 0.0004,
        }
    }
}

export async function getLevelPrice() {

    try {
        var web3 = readWeb3();

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "LEVEL_PRICE",
                contractAddress: config.contract,
                abi: BcashABI,
                calls: [
                    {
                        reference: "LEVEL_PRICE",
                        methodName: "LEVEL_PRICE",
                        methodParameters: [1],
                    },
                    {
                        reference: "LEVEL_PRICE",
                        methodName: "LEVEL_PRICE",
                        methodParameters: [2],
                    },
                    {
                        reference: "LEVEL_PRICE",
                        methodName: "LEVEL_PRICE",
                        methodParameters: [3],
                    },
                    {
                        reference: "LEVEL_PRICE",
                        methodName: "LEVEL_PRICE",
                        methodParameters: [4]
                    }
                ]
            }
        ];
        const results = await multicall.call(Contract);

        var levelPrice1 = await getFormatMulticall1(results, "LEVEL_PRICE", 0);
        levelPrice1 = (levelPrice1 && levelPrice1[0] && levelPrice1[0].hex) ? parseInt(levelPrice1[0].hex) : 0;
        levelPrice1 = levelPrice1 / 10 ** 18;


        var levelPrice2 = await getFormatMulticall1(results, "LEVEL_PRICE", 1);
        levelPrice2 = (levelPrice2 && levelPrice2[0] && levelPrice2[0].hex) ? parseInt(levelPrice2[0].hex) : 0;
        levelPrice2 = levelPrice2 / 10 ** 18;

        var levelPrice3 = await getFormatMulticall1(results, "LEVEL_PRICE", 2);
        levelPrice3 = (levelPrice3 && levelPrice3[0] && levelPrice3[0].hex) ? parseInt(levelPrice3[0].hex) : 0;
        levelPrice3 = levelPrice3 / 10 ** 18;

        var levelPrice4 = await getFormatMulticall1(results, "LEVEL_PRICE", 3);
        levelPrice4 = (levelPrice4 && levelPrice4[0] && levelPrice4[0].hex) ? parseInt(levelPrice4[0].hex) : 0;
        levelPrice4 = levelPrice4 / 10 ** 18;

        return {
            levelPrice1,
            levelPrice2,
            levelPrice3,
            levelPrice4
        }
    } catch (err) {
        return {
            levelPrice1: 0,
            levelPrice2: 0,
            levelPrice3: 0,
            levelPrice4: 0
        }
    }

}

export async function getAllowanceBalance() {

    try {
        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var address = get.address;
        var Contract = [
            {
                reference: "balanceOf",
                contractAddress: config.busdAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.contract],
                    },
                ]
            }];

        const results = await multicall.call(Contract);
        var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
        balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
        var allowance = await getFormatMulticall(results, "balanceOf", 1);
        allowance = parseInt(allowance.hex) / 10 ** 18;

        return {
            balanceOf,
            allowance
        }
    } catch (err) {
        return {
            balanceOf: 0,
            allowance: 0
        }
    }

}


export function getClassNameLevel(currentLevel) {
    currentLevel = parseFloat(currentLevel);
    var className1 = "selbox";
    var className2 = "selbox";
    var className3 = "selbox";
    var className4 = "selbox";

    if (currentLevel === 1) {
        className1 = "selbox active";
    } else if (currentLevel === 2) {
        className1 = "selbox finished_lvl";
        className2 = "selbox active";
    } else if (currentLevel === 3) {
        className1 = "selbox finished_lvl";
        className2 = "selbox finished_lvl";
        className3 = "selbox active";
    } else if (currentLevel === 4) {
        className1 = "selbox finished_lvl";
        className2 = "selbox finished_lvl";
        className3 = "selbox finished_lvl";
        className4 = "selbox active";
    }

    return {
        className1,
        className2,
        className3,
        className4
    }
}


export async function getUpgradedAmount() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);

        var userDetails = await Contract.methods.users(address).call();
        var upgradeAmount = userDetails.upgradeAmount;
        upgradeAmount = upgradeAmount / 10 ** 18;
        upgradeAmount = formatNumber(upgradeAmount);
        return {
            upgradeAmount: upgradeAmount,
            currentLevel: (userDetails && userDetails.currentLevel) ? userDetails.currentLevel : 0
        }
    } catch (err) {
        console.log(err, "errerrerr")
        return {
            upgradeAmount: 0
        }
    }
}


export async function upgradeRemaining() {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var Contract = new web3.eth.Contract(BcashABI, config.contract);

        var upgradeRemain = await Contract.methods.upgradeRemaining(address).call();
        var remaining = upgradeRemain / 10 ** 18;
        return {
            remaining,
            upgradeRemain
        }
    } catch (err) {
        return {
            remaining: 0,
            upgradeRemain: 0
        }
    }
}
