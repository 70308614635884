import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import $ from "jquery";

import { aboveDetail } from "../helper/common";

export default function Aboveinfo(props) {

  const [participant, setparticipant] = useState(0);
  const [latestUser, setlatestUser] = useState(0);

  useEffect(() => {
    loadScript1();
    getDetails();
  }, []);

  function loadScript1() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 80) {
        $(".above_info_box").addClass("above_fixed");
      } else {
        $(".above_info_box").removeClass("above_fixed");
      }
    });
  }


  async function getDetails() {

    let { totalUsers, todayjoin } = await aboveDetail();
    setparticipant(totalUsers);
    setlatestUser(todayjoin);

  }

  return (
    <section className="above_info_box">

      <div className="container">
        <Scrollbars style={{ width: "100%", height: 21 }}>
          <div className='above_box_flx'>
            <div>
              <p>All participants</p>
              <span> {participant}</span>
            </div>
            <div className='me-3' >
              <p>Joined in 24 hours </p>
              <span>{latestUser}</span>
            </div>
            {/* <div>
            <p>Profit users result </p>
            <span> 1 567 988 217</span>
          </div> */}
          </div>
        </Scrollbars>
      </div>
    </section>
  );
}