import React, { useState, useEffect } from "react";
import { toastAlert } from "../helper/toastAlert";
import { depositUpgrade } from "../pages/dashboard/dashboardinfo";
import { upgradeRemaining, getUpgradedAmount } from "../pages/dashboard/dashboardinfo";
import { approveToken } from "../pages/joinNow/joinnowdetail";
import { useSelector, useDispatch } from "react-redux";

import { setBalances } from "../reducers/Actions.js";
import { formatNumber } from "../helper/custom";
import { getAllowanceBalance } from "../helper/common";

import store from '../store';

var levelPrice = [0, 25, 40, 125, 450];

export default function DepositModal(props) {

    const userBalances = useSelector((state) => state.balances);
    const walletConnection = useSelector((state) => state.walletConnection);

    const dispatch = useDispatch();

    const [amount, setamount] = useState("");
    const [error, seterror] = useState("");
    const [isLoad, seisLoad] = useState(false);
    const [upgradevalue, setupgradevalue] = useState(0);
    const [availableAmt, setavailableAmt] = useState(0);
    const [nextLevel, setnextLevel] = useState(0);

    var { ondepositSuccess } = props;

    useEffect(() => {
        upgraded();
    }, [walletConnection, props.upgradeAmt]);

    async function upgraded(upgradeAmount) {
        var { remaining } = await upgradeRemaining();
        var { upgradeAmount, currentLevel } = await getUpgradedAmount();
        currentLevel = parseFloat(currentLevel) + 1
        setnextLevel(currentLevel);
        setavailableAmt(upgradeAmount);
        setupgradevalue(remaining);
    }

    async function submitDeposit() {
        seisLoad(true)
        try {
            if (await validation(parseFloat(upgradevalue))) {

                var currentProvider = store.getState();
                var balances = currentProvider.balances;
                var balance = parseFloat(balances.balance);

                // if (balance === 0) {
                //     seisLoad(false)
                //     toastAlert("error", "Please make sure you have gas fee in your wallet.");
                //     return;
                // }

                var chkAllowance = (userBalances && userBalances.tokenallowance) ? userBalances.tokenallowance : 0;
                if (userBalances && userBalances.isLoad === "no") {
                    var { allowance } = await getAllowanceBalance();
                    chkAllowance = allowance;
                }

                if (parseFloat(chkAllowance) < parseFloat(upgradevalue)) {
                    var { isAllowed, approvalAmt, error } = await approveToken();
                    if (error && error !== "") {
                        seisLoad(false)
                        toastAlert("error", error);
                        return;
                    } else if (parseFloat(approvalAmt) < parseFloat(upgradevalue)) {
                        toastAlert("error", "Insufficient approved");
                        return;
                    } else if (isAllowed) {
                        toastAlert("success", "Successfully approved");
                    }

                }
                var { upgradeRemain } = await upgradeRemaining();

                var { isdeposit, error } = await depositUpgrade(upgradeRemain);
                if (isdeposit) {
                    toastAlert("success", "Congratulations, successfully upgraded");
                    window.$("#deposit_modal").modal('hide');
                    setamount("");
                    setTimeout(async function () {
                        //window.location.reload(false);
                        ondepositSuccess();
                        upgraded();
                        var { balanceOf, allowance, bnbBal } = await getAllowanceBalance();
                        console.log(balanceOf, allowance, bnbBal, 'balanceOf, allowance, bnbBal')
                        balanceOf = formatNumber(balanceOf);
                        bnbBal = formatNumber(bnbBal);
                        dispatch(
                            setBalances({
                                tokenbalance: balanceOf,
                                tokenallowance: allowance,
                                balance: bnbBal,
                                isLoad: "yes"
                            })
                        );
                    }, 500)

                } else {
                    toastAlert("error", error);
                    if (error !== "Cancelled") {
                        window.$("#deposit_modal").modal('hide')
                        setamount("");
                    }
                }
            }
            seisLoad(false)
        } catch (err) {
            seisLoad(false)
            console.log(err, 'errerrerrerr')
        }
    }

    async function validation(value) {
        value = value;
        seterror("");
        var status = true;
        var balance = (userBalances && userBalances.tokenbalance) ? userBalances.tokenbalance : 0;
        if (userBalances && userBalances.isLoad === "no") {
            var { balanceOf } = await getAllowanceBalance();
            balance = balanceOf;
        }

        if (parseFloat(balance) < value) {
            //seterror("Insufficient balance");
            toastAlert("error", "Sorry unable to proceed, Please check your wallet in BUSD Balance");
            status = false;
        }

        console.log(status, '-----------')
        return status;
    }

    function CloseModal() {
        setamount("");
        seterror("");
    }

    return (
        <div
            className="modal fade primary_modal deposit_modal"
            id="deposit_modal"
            tabIndex={-1}
            aria-labelledby="depositModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-0">
                        <h5 className="modal-title" id="depositModalLabel">
                            Upgrade Wallet
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => CloseModal()}
                        ></button>
                    </div>
                    <div className="modal-body px-0">
                        <div>
                            <div className="mb-3 deposit_body">

                                {/* <span className="mb-3"> Your current upgrade amount: <span> {availableAmt} BUSD</span></span>
                                <span className="mb-3"> Your next level pending amount: <span> {upgradevalue} BUSD</span></span> */}
                                {levelPrice && levelPrice[nextLevel] && upgradevalue && parseFloat(availableAmt) >= parseFloat(upgradevalue) ?
                                    <span className="mb-3"> Please make sure you have gas fee in your wallet.</span>
                                    :
                                    <span className="mb-3"> Please make sure you have {upgradevalue} BUSD + gas fee in your wallet.</span>
                                }
                                <button disabled={isLoad} type="button" className="primary_btn mt-4" onClick={() => submitDeposit()}>
                                    Submit
                                    {isLoad && <i className="fas fa-spinner fa-spin"></i>}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
